.header-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 25vh;
  background-color: transparent;
  margin-top: 20px;
}

.top-line-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.header-name {
  font-size: 40px;
  font-family: 'Lucida Handwriting', serif;
  text-align: center;
  flex-shrink: 0;
  margin: 0;
}

.top-line {
  width: 80%;
  height: 2px;
  background-color: #deb887;
  margin: 10px auto;
}

.desktop-language-dropdown {
  position: absolute;
  right: 0;
  z-index: 9999;
}

.dropdown-toggle {
  font-size: 22px;
  text-align: center;
  background-color: transparent !important;
  text-decoration: none;
  padding: 8px 15px;
  margin: 0;
  margin-right: 10vh;
  border-radius: 0;
  border: none;
  transition: all 0.3s ease;
}

.dropdown-toggle:focus,
.dropdown-toggle:active,
.dropdown-toggle:hover {
  background-color: transparent !important;
  text-decoration: underline;
  color: black;
}

.dropdown-item {
  font-size: 18px;
  background-color: transparent !important;
  text-decoration: none;
  color: black;
  transition: all 0.3s ease;
}

.dropdown-item:hover {
  background-color: transparent !important;
  text-decoration: underline;
  color: black;
}

.custom-navbar {
  width: 90%;
  z-index: 1000;
  background-color: transparent;
  padding: 5px 0;
  font-family: 'Times New Roman', serif;
  font-size: 22px;
  margin-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-toggler {
  margin-left: 20px;
}

.nav-link {
  font-size: 22px;
  text-align: center;
  background-color: transparent;
  color: black;
  padding: 8px 15px;
  text-decoration: none;
  margin: 2px;
  border-radius: 0;
}

.nav-link:hover {
  text-decoration: underline;
  background-color: transparent;
}

.nav-link:active {
  text-decoration: underline;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .top-line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
  }

  .header-name {
    font-size: 24px;
    text-align: center;
    flex-grow: 1;
    margin: 0;
  }

  .dropdown-toggle {
    font-size: 18px;
  }

  .desktop-language-dropdown {
    flex-shrink: 0;
    position: relative;
    margin-left: 20px;
    z-index: 9999;
  }

  .custom-navbar {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 20px;
    width: 90%;
  }

  .navbar-toggler {
    margin-left: auto;
    margin-right: 20px;
  }

  .nav-link {
    font-size: 18px;
    text-align: left;
    padding: 10px 0;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .nav-link:hover {
    text-decoration: underline;
    color: black;
  }
}

@media screen and (max-width: 480px) {
  .header-name {
    font-size: 20px;
    text-align: left;
    flex-grow: 1;
    margin-left: 10px;
  }

  .dropdown-toggle {
    font-size: 18px;
  }

  .desktop-language-dropdown {
    margin-left: auto;
    position: relative;
    z-index: 9999;
  }

  .navbar-toggler {
    margin-left: auto;
    margin-right: 10px;
  }
}



















