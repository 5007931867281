.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 80px;
  background-color: #f8f8f8;
}

.content-wrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.first-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.left {
  flex: 0 0 80%;
  padding: 40px;
}

.right {
  flex: 0 0 20%;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.column {
  flex: 1;
  padding: 40px;
}

.centered-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border: 5px solid #808080;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  filter: grayscale(30%);
}

.headingHome {
  font-size: 40px;
  font-family: 'Lucida Handwriting', serif;
  color: #222;
  margin-bottom: 30px;
  align-self: flex-start;
}

.homeParagraph {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 80px;
}

.homeQuote {
  font-size: 22px;
  font-style: italic;
  margin-top: 30px;
  color: #976940;
}

.decorative-line {
  width: 90%;
  height: 2px;
  background-color: #976940;
  margin: 20px 0;
  border: none;
}

@media (max-width: 1200px) {
  .first-row,
  .row {
    flex-direction: column;
  }

  .left,
  .right,
  .column {
    flex: 1;
    padding: 20px;
  }

  .homeParagraph {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .headingHome {
    font-size: 32px;
  }

  .homeQuote {
    font-size: 20px;
  }
  
  .home-container {
    padding: 40px;
  }

  .decorative-line {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .headingHome {
    font-size: 28px;
  }

  .homeParagraph,
  .homeQuote {
    font-size: 16px;
  }
}

